import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Button,
  ButtonSize,
  ImageUploader,
  InputField,
  LoadingOverlay,
  SelectField,
} from "@/components";
import { eventsSelector, useGetRegionsQuery } from "@/pages/Private/redux";
import { useGetBrandsListQuery, useLazyGetBrandsListQuery } from "@/pages/Private/redux/brands.api";
import { useAppSelector } from "@/app/redux/hooks";
import { brandsSelector } from "@/pages/Private/redux/brands.slice";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { useGetProjectsListQuery } from "@/pages/Private/redux/projects.api";
import { eventTypesArray } from "@/app/constants";
import { supported_timezones } from "@/app/utils";

import { Features } from "../../../Companies/enums";
import {
  EventRequestBodyType,
  EventRequestBodySchema,
  NoAddressEventRequestBodySchema,
} from "../../event.schema";
import { BrandType } from "../../../Brands/schema/brand.schema";
import { isFestivalEvent } from "../EventSettings/pages";

interface EventSettingFormProps {
  description: string;
  onSubmit: (values: EventRequestBodyType) => void;
  initialValues: EventRequestBodyType;
  isLoading: boolean;
  isEditing?: boolean;
}

export const EventSettingForm: FunctionComponent<EventSettingFormProps> = ({
  description,
  onSubmit,
  initialValues,
  isLoading,
  isEditing,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.settings.general.${key}`);

  const { regions } = useAppSelector(eventsSelector);
  const { brands } = useAppSelector(brandsSelector);
  const [getBrandsList] = useLazyGetBrandsListQuery();

  const editGeneralSettingsPermission = useHasUserPermission(PermissionRoles.UPDATE_EVENT_GENERAL);

  useGetRegionsQuery();
  useGetBrandsListQuery({});
  const { data: projects } = useGetProjectsListQuery({});
  const { companies } = useCompanyOptions();

  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);
  const hasPermissionToGetCompanies = useHasUserPermission(PermissionRoles.GET_COMPANY);

  const regionOptions = useMemo(() => {
    return regions?.map((region: string) => {
      return { text: region, value: region };
    });
  }, [regions]);

  const eventTypeOptions = useMemo(() => {
    return eventTypesArray?.map((type) => {
      return { text: type.value, value: type.id };
    });
  }, []);

  const brandsOptions = useMemo(() => {
    return brands?.map((brands: BrandType) => {
      return { text: brands.name, value: brands.id };
    });
  }, [brands]);

  const projectsOptions = useMemo(() => {
    return projects?.data.map((project: { id: number; name: string }) => {
      return { text: project.name, value: project.id };
    });
  }, [projects?.data]);

  const timezonesOptions = useMemo(() => {
    return supported_timezones.map((timezone: string) => {
      return { text: timezone, value: timezone };
    });
  }, []);

  const companiesOptions = useMemo(() => {
    return companies?.data.map((company: { id: number; name: string }) => {
      return { text: company.name, value: company.id };
    });
  }, [companies?.data]);

  const defaultEventDate = useMemo(() => {
    return { date: "", fromDateTime: "", tillDateTime: "", isAfterMidnight: false };
  }, []);

  const [selectedEventType, setSelectedEventType] = useState("");

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, touchedFields, isValid },
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(
      isFestivalEvent(selectedEventType) ? EventRequestBodySchema : NoAddressEventRequestBodySchema
    ),
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "eventDates",
  });

  const { companyId, eventType, brandId } = watch();

  useEffect(() => {
    setSelectedEventType(eventType);
  }, [eventType]);

  useEffect(() => {
    if (!isLoading && companyId) {
      getBrandsList({ companyId });
    }
  }, [getBrandsList, companyId, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      reset(initialValues);
    }
  }, [initialValues, reset, isLoading]);

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Box alignItems="flex-start" display="flex" mt={4}>
      <Box
        bgcolor="white"
        borderRadius={2}
        boxShadow={1}
        flexGrow={1}
        maxWidth="844px"
        mr={3.75}
        p={2}
      >
        <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
          <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
            {ts("title")}
          </Typography>
          <Typography color="gray900.main" fontSize={14} fontWeight={400}>
            {description}
          </Typography>
        </Box>
        <Box>
          <Box
            alignItems="end"
            borderBottom="1px solid"
            borderColor="gray400.main"
            display="flex"
            mb={2}
            pb={2}
          >
            <Box mr={3.75} width="calc(40% - 7px)">
              <Typography display="block" mb={2} variant="mainBold">
                {ts("eventName")}
                <Typography color="red.main" component="sup">
                  *
                </Typography>
              </Typography>
              <Box width="100%">
                <InputField
                  border
                  disabled={!editGeneralSettingsPermission}
                  hasError={touchedFields.name && !!errors.name}
                  {...register("name")}
                  smallSize
                  placeholder={t("basics.name")}
                />
              </Box>
            </Box>
            <Box width="calc(40% - 7px)">
              <Typography display="block" mb={2.4} variant="mainBold">
                {ts("externalId")}
              </Typography>
              <Box width="100%">
                <InputField
                  border
                  hasError={touchedFields.externalId && !!errors.externalId}
                  {...register("externalId")}
                  smallSize
                  disabled={isEditing}
                  placeholder={ts("externalId")}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={3.75} width="calc(40% - 7px)">
              <Typography display="block" mb={2} variant="mainBold">
                {t(initialValues.name.length ? "terms.brand" : "events.eventType")}
                <Typography color="red.main" component="sup">
                  *
                </Typography>
              </Typography>
              <Box width="100%">
                <SelectField
                  border
                  hasIcon
                  smallSize
                  currentValue={(initialValues.name.length ? brandId : eventType) || "DEFAULT"}
                  disabled={!editGeneralSettingsPermission}
                  hasError={
                    initialValues.name.length
                      ? touchedFields.brandId && !!errors.brandId?.message
                      : touchedFields.eventType && !!errors.eventType?.message
                  }
                  options={(initialValues.name.length ? brandsOptions : eventTypeOptions) || []}
                  {...register(initialValues.name.length ? "brandId" : "eventType")}
                />
              </Box>
            </Box>
            <Box width="calc(40% - 7px)">
              <Typography display="block" mb={2} variant="mainBold">
                {t("terms.project")}
                <Typography color="red.main" component="sup">
                  *
                </Typography>
              </Typography>
              <Box width="100%">
                <SelectField
                  border
                  smallSize
                  currentValue={watch("projectId") || "DEFAULT"}
                  disabled={!editGeneralSettingsPermission}
                  hasError={touchedFields.projectId && !!errors.projectId?.message}
                  options={projectsOptions || []}
                  {...register("projectId")}
                />
              </Box>
            </Box>
          </Box>
          <Box borderBottom="1px solid" borderColor="gray400.main" display="flex" mb={2} pb={2}>
            {isMultiTenancyActive && hasPermissionToGetCompanies && (
              <Box mr={3.75} width="calc(40% - 7px)">
                <Typography display="block" mb={2} variant="mainBold">
                  {ts("eventCompany")}
                  <Typography color="red.main" component="sup">
                    *
                  </Typography>
                </Typography>
                <Box width="100%">
                  <SelectField
                    border
                    smallSize
                    currentValue={watch("companyId") || "DEFAULT"}
                    disabled={!editGeneralSettingsPermission}
                    hasError={touchedFields.companyId && !!errors.companyId?.message}
                    options={companiesOptions || []}
                    {...register("companyId")}
                  />
                </Box>
              </Box>
            )}
            {!initialValues.name.length && (
              <Box mr={3.75} width="calc(40% - 7px)">
                <Typography display="block" mb={2} variant="mainBold">
                  {t("terms.brand")}
                  <Typography color="red.main" component="sup">
                    *
                  </Typography>
                </Typography>
                <Box width="100%">
                  <SelectField
                    border
                    smallSize
                    currentValue={watch("brandId") || "DEFAULT"}
                    disabled={!editGeneralSettingsPermission}
                    hasError={touchedFields.brandId && !!errors.brandId?.message}
                    options={brandsOptions || []}
                    {...register("brandId")}
                  />
                </Box>
              </Box>
            )}
            <Box width="calc(40% - 7px)">
              <Typography display="block" mb={2} variant="mainBold">
                {t("terms.timezone")}
                <Typography color="red.main" component="sup">
                  *
                </Typography>
              </Typography>
              <Box width="100%">
                <SelectField
                  border
                  smallSize
                  currentValue={watch("timezone") || "DEFAULT"}
                  disabled={!editGeneralSettingsPermission}
                  hasError={touchedFields.timezone && !!errors.timezone?.message}
                  options={timezonesOptions || []}
                  {...register("timezone")}
                />
              </Box>
            </Box>
          </Box>
          {isFestivalEvent(eventType) && (
            <>
              <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
                <Typography display="block" mb={2.5} variant="mainBold">
                  {ts("eventTime")}
                  <Typography color="red.main" component="sup">
                    *
                  </Typography>
                </Typography>
                {fields?.map((date, i) => (
                  <Box
                    key={`event-days-${date.id}`}
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    mb={1}
                    sx={{
                      ":last-child": {
                        mb: 2,
                      },
                    }}
                  >
                    <Box alignItems="center" display="flex">
                      <Typography fontWeight={600} mr={2} variant="main12">
                        {t("basics.day")} {i + 1}
                      </Typography>
                      <Box>
                        <InputField
                          {...register(`eventDates.${i}.date`)}
                          border
                          smallSize
                          disabled={!editGeneralSettingsPermission}
                          type="date"
                        />
                      </Box>
                    </Box>
                    <Box alignItems="center" display="flex">
                      <Typography fontWeight={600} mr={2} variant="main12">
                        {ts("startHour")}
                      </Typography>
                      <Box width={76}>
                        <InputField
                          {...register(`eventDates.${i}.fromDateTime`)}
                          border
                          smallSize
                          disabled={!editGeneralSettingsPermission}
                          type="time"
                        />
                      </Box>
                    </Box>
                    <Box alignItems="center" display="flex">
                      <Typography fontWeight={600} mr={2} variant="main12">
                        {ts("endHour")}
                      </Typography>
                      <Box width={76}>
                        <InputField
                          {...register(`eventDates.${i}.tillDateTime`)}
                          border
                          smallSize
                          disabled={!editGeneralSettingsPermission}
                          type="time"
                        />
                      </Box>
                    </Box>
                    <Box
                      alignItems="center"
                      bgcolor="gray300.main"
                      display="flex"
                      maxWidth={182}
                      p="2px 4px"
                    >
                      <Checkbox
                        {...register(`eventDates.${i}.isAfterMidnight`)}
                        disableRipple
                        defaultChecked={date.isAfterMidnight}
                        disabled={!editGeneralSettingsPermission}
                        size="small"
                        sx={{
                          color: "#E2E2E2",
                          mr: 0.5,
                          "&.MuiButtonBase-root": {
                            padding: 0,
                          },
                        }}
                      />
                      <Typography color="gray500.main" lineHeight={1} variant="gray12">
                        {ts("eventContinue")}
                      </Typography>
                    </Box>
                    {editGeneralSettingsPermission && (
                      <Box width="10%">
                        <Typography
                          color="red.main"
                          fontWeight={700}
                          variant="link"
                          onClick={() => remove(i)}
                        >
                          {t("basics.remove")}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
                {editGeneralSettingsPermission && (
                  <Box textAlign="center">
                    <Typography
                      color="green.main"
                      fontWeight={700}
                      variant="link"
                      onClick={() => append(defaultEventDate)}
                    >
                      {ts("addDay")}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
                <Typography display="block" mb={2} variant="mainBold">
                  {ts("eventLocation")}
                </Typography>
                <Box display="flex" mb={1.5}>
                  <Box mr={3.75} width="40%">
                    <InputField
                      border
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.name && !!errors.name}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("street")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      {...register("street")}
                      smallSize
                      placeholder={ts("street")}
                    />
                  </Box>
                  <Box width="40%">
                    <InputField
                      border
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.houseNumber && !!errors.houseNumber}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("streetNumber")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      {...register("houseNumber")}
                      smallSize
                      placeholder={ts("streetNumber")}
                    />
                  </Box>
                </Box>
                <Box display="flex" mb={1.5}>
                  <Box mr={3.75} width="40%">
                    <InputField
                      border
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.city && !!errors.city}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("city")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      {...register("city")}
                      smallSize
                      placeholder={ts("city")}
                    />
                  </Box>
                  <Box width="40%">
                    <InputField
                      border
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.zip && !!errors.zip}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("postalCode")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      {...register("zip")}
                      smallSize
                      placeholder={ts("postalCode")}
                    />
                  </Box>
                </Box>
                <Box display="flex" mb={1.5}>
                  <Box width="calc(40% - 7px)">
                    <SelectField
                      border
                      smallSize
                      currentValue={watch("nielsen") || "DEFAULT"}
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.nielsen && !!errors.nielsen}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("nielsen")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      options={regionOptions || []}
                      {...register("nielsen")}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <Box maxWidth={128}>
            <Button
              handleClick={handleSubmit(onSubmit)}
              isDisabled={!isValid || !editGeneralSettingsPermission}
              isLoading={isLoading}
              size={ButtonSize.M}
              title={t("basics.save")}
            />
          </Box>
        </Box>
      </Box>
      {editGeneralSettingsPermission && (
        <Box bgcolor="white" borderRadius={2} boxShadow={1} p={2} width="calc(100% - 874px)">
          <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={2}>
            {ts("eventImage")}
          </Typography>
          <Box display="flex" mb={1.5} minHeight={240}>
            <ImageUploader
              border
              currentImgUrl={getValues("image")}
              setCurrentImgUrl={(img) => setValue("image", img, { shouldValidate: true })}
            />
          </Box>
          <Typography fontWeight={400} variant="main14">
            {t("basics.imageRatioNote")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
