import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { useAppSelector } from "@/app/redux/hooks";
import { Loader, NoDataFound } from "@/components";
import { eventsSelector } from "@/pages/Private/redux";
import { formatInTimeZone } from "@/app/utils";

interface EventDatesActivityMonitorProps {
  isFetching: boolean;
}
export const EventDatesActivityMonitor: FunctionComponent<EventDatesActivityMonitorProps> = ({
  isFetching,
}) => {
  const { t } = useTranslation();

  const { eventDetails } = useAppSelector(eventsSelector);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      display="flex"
      flexDirection="column"
      minHeight={350}
      px={1.5}
      py={2}
      sx={{ width: { md: "30%" }, mb: { xs: 3, md: 0 } }}
    >
      <Typography pb={3} variant="mainBold">
        {t("events.datesActivityMonitor")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : !eventDetails?.eventDates.length ? (
        <NoDataFound height="150px" kpi={t("events.datesActivityMonitor")} />
      ) : (
        <>
          <Box
            alignItems="center"
            borderBottom="1px solid #E3E3E3"
            display="flex"
            justifyContent="space-between"
            py={2}
          >
            <Typography variant="gray14">{t("basics.day")}</Typography>
            <Typography variant="gray14">{t("basics.data")}</Typography>
          </Box>
          {eventDetails?.eventDates.map((date) => (
            <Box
              key={date.id}
              alignItems="center"
              borderBottom="1px solid #E3E3E3"
              display="flex"
              justifyContent="space-between"
              py={2}
            >
              <Typography variant="main14">
                {formatInTimeZone(new Date(date.fromDateTime), "dd.MM.yyyy", eventDetails.timezone)}
              </Typography>
              {date.dataAvailabilityCount ? (
                <CheckCircleOutlineIcon sx={{ fontSize: 26, color: "green.main" }} />
              ) : (
                <HighlightOffIcon sx={{ fontSize: 26, color: "gray600.main" }} />
              )}
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
