import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Bullets, LineChart, LineChartSeries, Loader, Tabs } from "@/components";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum, NewsletterWithoutDeviceSpecifics } from "../../../../types";

interface TimelineProps {
  kpis: KpiType[];
  dates: { start: Date; end: Date };
  isFiltration?: boolean;
  isLoading?: boolean;
  isProjectReporting?: boolean;
}

export const Timeline: FunctionComponent<TimelineProps> = ({
  kpis,
  dates,
  isLoading,
  isProjectReporting,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(KpiTypeEnum.NEWSLETTER_DATA);

  const isDOINLGraphActive = useIsFeatureActive(Features.GRAPH_DIO_NL);
  const isActivationsGraphActive = useIsFeatureActive(Features.GRAPH_ACTIVATIONS);
  const isSalesGraphActive = useIsFeatureActive(Features.GRAPH_SALES);

  const tabs = useMemo(() => {
    const defaultTabs = [];

    if (isDOINLGraphActive) {
      defaultTabs.push({
        text: t(`events.kpis.${KpiTypeEnum.NEWSLETTER_DATA}`),
        value: KpiTypeEnum.NEWSLETTER_DATA,
      });
    }

    if (isActivationsGraphActive) {
      defaultTabs.push({
        text: t(`events.kpis.${KpiTypeEnum.ACTIVATIONS}`),
        value: KpiTypeEnum.ACTIVATIONS,
      });
    }

    if (isSalesGraphActive) {
      defaultTabs.push({ text: t(`events.kpis.${KpiTypeEnum.SALES}`), value: KpiTypeEnum.SALES });
    }

    return defaultTabs.filter((tab) => kpis.some((kpi) => kpi.name === tab.value));
  }, [t, kpis, isDOINLGraphActive, isActivationsGraphActive, isSalesGraphActive]);

  const seriesData = useMemo(() => {
    const categories: number[] = [];
    const data: LineChartSeries[] = [
      {
        name: selectedTab,
        type: "line",
        data: [],
      },
      {
        name: KpiTypeEnum.PROMOTER_HOURS,
        type: "line",
        data: [],
      },
      {
        name: KpiTypeEnum.VISITORS,
        type: "line",
        data: [],
      },
    ];

    const firstDate = dates.start.getTime() - 60 * 60 * 1000;
    const lastDate = dates.end.getTime() + 60 * 60 * 1000;

    if (isProjectReporting) {
      const setOfUnixTimestamps = Array.from(
        new Set(
          data.flatMap((row) => {
            const kpiData = kpis.find((k) => k.name === row.name)?.data ?? [];
            const existedTimes =
              kpiData.find((d) =>
                row.name === KpiTypeEnum.NEWSLETTER_DATA
                  ? d.specific === NewsletterWithoutDeviceSpecifics.DOI_NL
                  : d.specific === null
              )?.time ?? [];

            return existedTimes.map((o) => new Date(o.time || "").getTime());
          })
        )
      ).sort((a, b) => a - b);

      setOfUnixTimestamps.forEach((unixMilisec) => categories.push(unixMilisec));
    } else {
      for (let i = firstDate; i <= lastDate; i += 15 * 60 * 1000) {
        categories.push(i);
      }
    }

    const series = data.map((row) => {
      const kpiData = kpis.find((k) => k.name === row.name)?.data ?? [];
      const existedTimes =
        kpiData.find((d) =>
          row.name === KpiTypeEnum.NEWSLETTER_DATA
            ? d.specific === NewsletterWithoutDeviceSpecifics.DOI_NL
            : d.specific === null
        )?.time ?? [];

      row.data = categories.map(
        (unixMillisec) =>
          existedTimes.find((time) => unixMillisec === new Date(time.time || "").getTime())
            ?.value ?? 0
      );

      return { ...row, name: t(`events.kpis.${row.name}`) };
    });

    return { series, categories };
  }, [kpis, t, selectedTab, dates, isProjectReporting]);

  return (
    <>
      {!isDOINLGraphActive && !isActivationsGraphActive && !isSalesGraphActive ? (
        <></>
      ) : (
        <Box
          sx={{
            mb: { xs: 3, md: 0 },
            mr: "30px",
            bgcolor: { md: "white" },
            borderRadius: { md: 2 },
            boxShadow: { md: 1 },
            p: { md: 2 },
          }}
          width="100%"
        >
          <Box
            sx={{
              display: { md: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography component="h3" mb={2} variant="mainBold">
              {t("events.performanceTimeline")}
            </Typography>
            <Tabs
              excludeFromSearch
              handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
              selectedTab={selectedTab}
              tabs={tabs}
            />
          </Box>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box
                height={274}
                px={1.5}
                py={2}
                sx={{
                  bgcolor: { xs: "white", md: "transparent" },
                  borderRadius: { xs: 2, md: 0 },
                  boxShadow: { xs: 1, md: 0 },
                  mt: { xs: 2, md: 4 },
                }}
              >
                <LineChart categories={seriesData.categories} series={seriesData.series} />
              </Box>
              <Box sx={{ display: { md: "none" } }}>
                <Bullets
                  count={tabs.length}
                  selectedIndex={tabs.findIndex((t) => t.value === selectedTab)}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
