import { Box } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "@/app/redux/hooks";
import { LoadingOverlay } from "@/components";
import { DoiByAgeGroupsDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiByAgeDonutChart";
import { DoiByAgegroupByGenderHeatmap } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiByAgegroupByGenderHeatmap";
import { DoiByGenderDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiByGenderDonutChart";
import { DoiDistributionByCityTable } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiDistributionByCityTable";
import { DoiDistributionDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiDistributionDonutChart";
import { DoiDistributionKpiCard } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiDistributionKpiCard";
import { DoiTotalRegistrations } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiTotalRegistrations";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { DoiByDayDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiByDayDonutChart";
import { DoiTimelineChart } from "@/pages/Private/pages/Events/pages/EventDetails/DOIDashboard/components/DoiTimelineChart";

export const ProjectByBrandDoiDashboard: FunctionComponent = () => {
  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);
  const { dashboard } = useParams();

  const timeLineDates = useMemo(() => {
    const startDate = project?.eventDates[0]?.fromDateTime ?? "";
    const endDate = project?.eventDates[project.eventDates.length - 1]?.tillDateTime ?? "";
    const start = new Date(startDate);
    const end = new Date(endDate);

    return { start, end };
  }, [project]);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            pb: { xs: 1.5, md: 1 },
            minWidth: { xs: 266, md: 500 },
          }}
        >
          <DoiTotalRegistrations isFetching={!project} kpis={project?.data} />
          <DoiDistributionKpiCard
            isDoiDashboard={dashboard === "doi"}
            isFetching={!project}
            kpis={project?.data}
          />
        </Box>
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <DoiDistributionDonutChart isFetching={!project} kpis={project?.data} />
          <DoiByGenderDonutChart isFetching={!project} kpis={project?.data} />
          <DoiByAgeGroupsDonutChart isFetching={!project} kpis={project?.data} />
          <DoiByDayDonutChart isFetching={!project} kpis={project?.data} />
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <DoiTimelineChart
          dates={timeLineDates}
          isLoading={!project}
          isProjectReporting={true}
          kpis={project?.data}
        />
        <DoiDistributionByCityTable doiKPIs={project?.data} isFetching={!project} />
      </Box>
      <DoiByAgegroupByGenderHeatmap kpis={project?.data} />
    </>
  );
};
