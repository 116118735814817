import { Fab } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS?: {
      triggerFunction?: (showCollectorDialog: () => void) => void;
      noDefaultButton?: boolean;
    };
    showJiraCollectorDialog?: () => void;
  }
}

export const JiraIssueCollector: FunctionComponent = () => {
  useEffect(() => {
    window.ATL_JQ_PAGE_PROPS = {
      ...window.ATL_JQ_PAGE_PROPS,
      noDefaultButton: true,
      triggerFunction: (showCollectorDialog) => {
        window.showJiraCollectorDialog = showCollectorDialog;
      },
    };

    // Load Jira script if not already present
    const jiraCollectorScript = document.querySelector(
      `script[src="https://bsure.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2b963057"]`
    );

    if (!jiraCollectorScript) {
      const script = document.createElement("script");

      script.src =
        "https://bsure.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2b963057";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const handleButtonClick = () => {
    if (window.showJiraCollectorDialog) {
      window.showJiraCollectorDialog();
    } else {
      console.error("Jira Issue Collector dialog function not initialized.");
    }
  };

  return (
    <Fab
      aria-label="help"
      color="primary"
      size="medium"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
      }}
      onClick={handleButtonClick}
    >
      <HelpOutlineIcon />
    </Fab>
  );
};
