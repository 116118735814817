import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";

import { ActivationsTimelineChart } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsTimeline";
import { LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { ActivationsTotalTile } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsTotalTile";
import { ActivationsByTypeDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsByTypeDonutChart";
import { ActivationsByDayDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsByDayDonutChart";
import { ActivationsTopTenTable } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsTopTenTable";
import { ActivationsByTypeHeatmap } from "@/pages/Private/pages/Events/pages/EventDetails/ActivationsDashboard/components/ActivationsByTypeHeatmap";
import { berlinTimeZone } from "@/app/utils";

export const ProjectByBrandActivationsDashboard: FunctionComponent = () => {
  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);

  const timeLineDates = useMemo(() => {
    const startDate = project?.eventDates[0]?.fromDateTime ?? "";
    const endDate = project?.eventDates[project.eventDates.length - 1]?.tillDateTime ?? "";
    const start = new Date(startDate);
    const end = new Date(endDate);

    return { start, end };
  }, [project]);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <ActivationsTotalTile isFetching={!project} kpis={project?.data} />
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <ActivationsByTypeDonutChart isFetching={!project} kpis={project?.data} />
          <ActivationsByDayDonutChart isFetching={!project} kpis={project?.data} />
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <ActivationsTimelineChart
          dates={timeLineDates}
          isLoading={!project}
          isProjectReporting={true}
          kpis={project?.data}
        />
        <ActivationsTopTenTable activationsKPIs={project?.data} isFetching={!project} />
      </Box>
      <ActivationsByTypeHeatmap
        isLoading={!project}
        kpis={project.data}
        timezone={project?.eventDates[0].timezone ?? berlinTimeZone}
      />
    </>
  );
};
