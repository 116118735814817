import { addDays, format } from "date-fns";
import { utcToZonedTime, toDate } from "date-fns-tz";

import { EventDatesRequestBodyType } from "@/pages/Private/pages/Events/event.schema";

export const berlinTimeZone = "Europe/Berlin";
export const supported_timezones = [berlinTimeZone];

/**
 * @param eventDates Array of event dates
 * @param tz Timezone in IANA format
 */
export const convertEventDates = (eventDates: EventDatesRequestBodyType[], tz: string) => {
  return eventDates?.map((dateTime) => {
    const fromDateTime = toDate(`${dateTime.date} ${dateTime.fromDateTime}`, { timeZone: tz });
    const tillDateTime = toDate(`${dateTime.date} ${dateTime.tillDateTime}`, { timeZone: tz });

    return {
      fromDateTime: fromDateTime.toString(),
      tillDateTime: dateTime.isAfterMidnight
        ? addDays(tillDateTime, 1).toString()
        : tillDateTime.toString(),
    };
  });
};

export const formatInTimeZone = (date: Date, fmt: string, timezone: string) =>
  format(utcToZonedTime(date, timezone), fmt);

/**
 * Formatter function created for Apex Charts to **convert timestamp + timezone to a date string** (example "16 Dec 15:55")
 * @param params Object with the timestamp and timezone
 * @returns Date string (example "16 Dec 15:55")
 */
export const chartsLabelFormatter = ({
  timestamp,
  timezone,
}: {
  timestamp: number;
  timezone: string;
}) => {
  const toBerlinTimezone = utcToZonedTime(new Date(timestamp), timezone ?? "Etc/UTC");

  const day_of_date = toBerlinTimezone.getDate();
  const monthName = new Date(toBerlinTimezone).toDateString().split(" ")[1];
  const hours = toBerlinTimezone.getHours();
  const minutes = toBerlinTimezone.getMinutes();

  const hh = hours < 10 ? `0${hours}` : hours;
  const mm = minutes < 10 ? `0${minutes}` : minutes;

  return `${day_of_date} ${monthName} ${hh}:${mm}`;
};
