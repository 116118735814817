import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { HeatmapChart, Loader } from "@/components";
import { formatInTimeZone } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
  timezone: string;
}

export const Heatmap: FunctionComponent<HeatmapProps> = ({ kpis, isLoading, timezone }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const heatmapLabels = useMemo(() => {
    const labels = [];

    for (let i = 0; i < 24; i++) {
      labels.push(`${i < 10 ? `0${i}` : i}:00`);
    }

    return labels;
  }, []);

  const heatmapData = useMemo(() => {
    const heatmapValues: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const footfallFrequency = [
      ...(kpis.find((k) => k.name === KpiTypeEnum.FOOTFALL)?.data[0].hour ?? []),
    ];

    footfallFrequency
      .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
      .forEach((item) => {
        const day: string = formatInTimeZone(new Date(item.time), "iii", timezone);
        const hour = Number(formatInTimeZone(new Date(item.time), "HH", timezone));

        const foundDay = heatmapValues.find((v) => v.name === day);
        const fullHour = `${hour < 10 ? `0${hour}` : hour}:00`;

        if (foundDay) {
          const foundData = foundDay.data.find((d) => d.x === fullHour);

          if (foundData) {
            foundData.y += item.value;
          } else {
            foundDay.data.push({
              x: fullHour,
              y: item.value,
            });
          }
        } else {
          const heatmapValuesData = heatmapLabels.map((label) => {
            const y = label === fullHour ? item.value : 0;

            return {
              x: label,
              y: y,
            };
          });

          heatmapValues.push({
            name: day,
            data: heatmapValuesData,
          });
        }
      });

    return heatmapValues;
  }, [heatmapLabels, kpis, timezone]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Typography mb={2} variant="mainBold">
        {ts("footfallFrequency")}
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={ts("footfallFrequency").toLowerCase()}
          labels={heatmapLabels}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
