import { FunctionComponent, PropsWithChildren } from "react";
import { Box } from "@mui/material";

import { Header } from "./Header/Header";
import { SideBar } from "../SideBar/SideBar";
import { JiraIssueCollector } from "../IssueCollectorWidget/IssueCollectorWidget";

export const MainLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Box bgcolor="gray100.main" minHeight="100vh" width="100vw">
      <Header />
      <SideBar />
      <Box
        sx={{
          p: { xs: 2, md: 2.5 },
          // width: { xs: "calc(100% - 32px)", md: "calc(100% - 40px)" },
          // height: { xs: "calc(100% - 96px)", md: "calc(100% - 114px)" },
        }}
      >
        {children}
        <JiraIssueCollector />
      </Box>
    </Box>
  );
};
