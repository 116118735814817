import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { berlinTimeZone } from "@/app/utils";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import { LoadingOverlay } from "@/components";

import { SalesByProductTypeBarChart } from "./components/SalesByProductTypeBarChart";
import { SalesTotalAndRealPackages } from "./components/SalesTotalAndRealPackages";
import { SalesTimeline } from "./components/SalesTimeline";
import { SalesByProductHeatmap } from "./components/SalesByProductHeatmap";
import { SoldProductsByBrand } from "./components/SalesProductsByBrand";
import { TopTenProductTable } from "./components/TopTenProductTable";
import { TopTenModuleTable } from "./components/TopTenModulesTable";
import { SalesByBrandDonutChart } from "./components/SalesByBrandDonutChart";
import { SalesByProductByModuleHeatmap } from "./components/SalesByProductByModuleHeatmap";
import { EventNotes } from "../OverviewDashboard/components/EventNotes";
import { SalesTotalAndRealEvoPackages } from "./components/SalesTotalAndRealEvoPacks";
import { SalesTotalDeviceAndAccessoriesTile } from "./components/SalesTotalDeviceAndAccessoriesTile";
import { SalesByDeviceAndPacksDonutChart } from "./components/SalesByDeviceAndPacksDonutChart";
import { SalesByTypeDonutChart } from "./components/SalesByTypeDonutChart";
import { SalesByAccessoriesDonutChart } from "./components/SalesByAccessoriesDonutChart";
import { EventTypes, KpiTypeEnum } from "../../../types";
import { SalesByDayHeatmapChart } from "./components/SalesByDayHeatmapChart";
import { SalesTotalSoldProducts } from "./components/SalesTotalSoldProducts";
import { SalesByDayDonutChart } from "./components/SalesByDayDonutChart";
import { SalesByCategoryDonutChart } from "./components/SalesByCategoryDonutChart";

interface EventSalesDashboardProps {
  eventDateId: number | null;
  selectedDateRange?: boolean;
  isFetching: boolean;
}
export const EventSalesDashboard: FunctionComponent<EventSalesDashboardProps> = ({
  eventDateId,
  selectedDateRange,
  isFetching,
}) => {
  const { t } = useTranslation();

  const { eventDetails, selectedEvent } = useAppSelector(eventsSelector);

  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isPloomFestival = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = selectedEvent?.eventDates.find((date) => date.id === eventDateId);

      return {
        start: new Date(eventDate?.fromDateTime ?? ""),
        end: new Date(eventDate?.tillDateTime ?? ""),
      };
    }

    return {
      start: new Date(selectedEvent?.eventDates.map((date) => date.fromDateTime)[0] ?? ""),
      end: new Date(
        selectedEvent?.eventDates.map((date) => date.tillDateTime)[
          selectedEvent?.eventDates.length - 1
        ] ?? ""
      ),
    };
  }, [selectedEvent, eventDateId]);

  return !selectedEvent ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: isPloomFestival ? "column" : "row" },
          justifyContent: "space-between",
          gap: isPloomFestival ? 1 : 0,
          width: isPloomFestival ? "fit-content" : "100%",
        }}
      >
        {isPloomEvent ? (
          <Box
            sx={{
              display: "flex",
              gap: { xs: 0, md: 1 },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {isPloomFestival && (
              <SalesTotalSoldProducts
                isFetching={isFetching}
                ploomSalesKPIs={selectedEvent?.data}
              />
            )}
            <SalesTotalAndRealEvoPackages
              isFetching={isFetching}
              ploomSalesKPIs={selectedEvent?.data}
            />
            <SalesTotalDeviceAndAccessoriesTile
              isFetching={isFetching}
              ploomSalesKPIs={selectedEvent?.data}
            />
          </Box>
        ) : (
          <SalesTotalAndRealPackages isFetching={isFetching} salesKPIs={selectedEvent?.data} />
        )}

        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          {isPloomEvent ? (
            <>
              <SalesByTypeDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                ploomSalesKPIs={selectedEvent?.data}
              />
              <SalesByAccessoriesDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                ploomSalesKPIs={selectedEvent?.data}
              />
              {isPloomFestival && (
                <SalesByDayDonutChart
                  isFetching={isFetching}
                  isFiltration={!!eventDateId}
                  salesKPIs={selectedEvent?.data}
                />
              )}
              <SalesByDeviceAndPacksDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                ploomSalesKPIs={selectedEvent?.data}
              />
            </>
          ) : (
            <>
              <SalesByBrandDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                salesKPIs={selectedEvent?.data}
              />
              <SalesByDayDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                salesKPIs={selectedEvent?.data}
              />
              <SalesByCategoryDonutChart
                isFetching={isFetching}
                isFiltration={!!eventDateId}
                salesKPIs={selectedEvent?.data}
              />
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        {isPloomEvent ? (
          <SalesByProductTypeBarChart
            defaultTab={KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY}
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            salesKPIs={selectedEvent?.data}
          />
        ) : (
          <SoldProductsByBrand
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            salesKPIs={selectedEvent?.data}
          />
        )}
        <TopTenProductTable
          isPloomEvent
          isDateRangeFiltering={selectedDateRange}
          isFetching={isFetching}
          isFiltration={!!eventDateId}
          salesKPIs={selectedEvent?.data}
        />
      </Box>

      {isPloomEvent && (
        <Box
          sx={{
            display: { md: "flex" },
            mb: { xs: 2, md: 1 },
          }}
        >
          <SalesByProductTypeBarChart
            defaultTab={KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE}
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            salesKPIs={selectedEvent?.data}
          />
          {!isPloomFestival && (
            <TopTenModuleTable
              isPloomEvent
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              salesKPIs={selectedEvent?.data}
            />
          )}
        </Box>
      )}

      <Box
        sx={{
          mb: { xs: 2, md: 1 },
        }}
      >
        {isPloomEvent && !isPloomFestival ? (
          <SalesByDayHeatmapChart
            defaultTab={KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY}
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={selectedEvent.data}
            timezone={eventDetails?.timezone ?? berlinTimeZone}
          />
        ) : (
          <SalesByProductHeatmap
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={selectedEvent.data}
            timezone={eventDetails.timezone}
          />
        )}
      </Box>
      {!isPloomEvent && (
        <>
          <Box borderBottom="2px solid" borderColor="gray400.main" my={2} width="100%">
            <Typography variant="main28bold">{t("events.moduleInsights")}</Typography>
          </Box>
          <Box
            sx={{
              display: { md: "flex" },
              mb: { xs: 2, md: 1 },
            }}
          >
            <SalesTimeline
              dates={timeLineDates}
              eventDates={selectedEvent?.eventDates.map((eD) => eD.fromDateTime)}
              isFiltration={!!eventDateId}
              isLoading={isFetching}
              kpis={selectedEvent.data}
            />
            <TopTenModuleTable
              isFetching={isFetching}
              isFiltration={!!eventDateId}
              salesKPIs={selectedEvent?.data}
            />
          </Box>
          <Box
            sx={{
              mb: { xs: 2, md: 1 },
            }}
          >
            <SalesByProductByModuleHeatmap
              isFiltration={!!eventDateId}
              isLoading={isFetching}
              kpis={selectedEvent.data}
            />
          </Box>
          <EventNotes eventId={selectedEvent.id} notes={selectedEvent.notes} />
        </>
      )}
    </>
  );
};
