import { FunctionComponent, useMemo } from "react";
import { default as Chart } from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

import { chartsLabelFormatter } from "@/app/utils";
import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";
import { useWindowSize } from "@/app/hooks/useWindowSize";

import { NoDataFound } from "../NoDataFound/NoDataFound";

export interface LineChartSeries {
  name: string;
  type: "line" | "area" | "column";
  data: number[];
  color?: string;
}

interface LineChartProps {
  series: LineChartSeries[];
  categories: number[];
  stroke?: number[];
  daysOnly?: boolean;
}

export const LineChart: FunctionComponent<LineChartProps> = ({
  series,
  categories,
  stroke,
  daysOnly,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { eventDetails } = useAppSelector(eventsSelector);
  const isWithoutData = series.every((s) => s.data.every((v) => v === 0));
  const theme = useTheme();

  const mainData = useMemo(
    () => series.find((s) => s.name !== t("events.visitors") && s.type === "line"),
    [series, t]
  );

  const mainDataLength = mainData?.data.length ?? 1;

  return isWithoutData ? (
    <NoDataFound kpi={t("events.performanceTimeline").toLowerCase()} />
  ) : (
    <Chart
      height="100%"
      options={{
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          redrawOnParentResize: true,
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: width < 900,
          },
        },
        legend: {
          show: true,
        },
        annotations: {
          xaxis: [
            {
              ...(mainDataLength !== categories.length && {
                x: categories[mainDataLength - 1],
                borderColor: "#54D62C",
                strokeDashArray: 4,
              }),
            },
          ],
        },
        colors: theme.palette.chartsPalette.lineChart,
        stroke: {
          width: stroke || [2, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: width > 900 ? "20px" : "30px",
          },
        },
        forecastDataPoints: {
          count: 0,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: width > 900,
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories,
          tickAmount: width > 900 ? 10 : 3,
          tooltip: {
            enabled: true,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            datetimeFormatter: {
              day: "dd MMM",
              hour: "HH:mm",
            },
            formatter(value, timestamp) {
              return chartsLabelFormatter({
                timestamp: timestamp ?? Number(value) ?? 0,
                timezone: eventDetails?.timezone ?? "Etc/UTC",
              });
            },
          },
          axisBorder: {
            show: true,
            color: "#919EAB",
            offsetX: 0,
            offsetY: -1,
          },
        },
        tooltip: {
          x: {
            format: daysOnly ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm",
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: width > 900,
          },
          axisBorder: {
            show: width <= 900,
            color: "#919EAB",
            offsetX: width > 900 ? 2 : -9,
            offsetY: 0,
          },
        },
        fill: {
          opacity: 0.7,
        },
      }}
      series={series}
      type="line"
      width="100%"
    />
  );
};
